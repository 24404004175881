//---- Dependencies ----//
import "./Components/Menu/Settings/theme/classNameGenerator";
import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, browserHistory, IndexRedirect } from "react-router";
import { I18nextProvider } from "react-i18next";
import { SnackbarProvider } from "notistack";
import ReactGA from 'react-ga';
import { StylesProvider } from "@material-ui/core";
import CssBaseline from '@mui/material/CssBaseline';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import i18n from "./i18n";
import store from "./Store";

//---- Actions ----//
import { SetLocales, SetUserAndConnection, SetUserPermissions } from "./Actions/User";

//---- Commons ----//
import CloseSnackbar from "./Components/Common/CloseSnackbar";
import GetCookie from "./Components/Common/Functions/GetCookie";

//---- CSS ----//
import "./Icons/styles.css";
import "./NewIcons/styles.css";
import "./Style/Global.css";
import 'react-virtualized/styles.css';
import "./Components/Menu/MaterialTripList/MailVisualEditor/css/index.css";

//---- Components ----//
import asyncComponent from "./AsyncComponent.js";
import SetCookie from "./Components/Common/Functions/SetCookie";
import { ThemeProvider } from "./Components/Menu/Settings/theme/themeProvider";
import { ToggleAccommodationRecommended } from "./Actions/Accommodation";
import { SetCurrencyList } from "./Actions/Base";
import CheckBeforeRequest from "./Components/Common/CheckBeforeRequest";
import Notify from "./Components/Common/Notify";

import { generateClassName } from "./theme";
import { resetCircuit } from "./Components/pages/redux/circuitSlice.ts";

const identifier = window.location.host.replace("www.", "").split('.')[0];

//---- NotFound ----//
const NotFound = asyncComponent(() =>
    import("./Components/NotFound/NotFound.jsx")
);

//---- Base -> Header - Component ----//
const Base = asyncComponent(() =>
    import("./Components/Base/Base.jsx")
);

//---- Itinerary ----//
const Itinerary = asyncComponent(() =>
    import("./Components/Itinerary/Itinerary.tsx")
);

//---- PassengerGroup ----//
const FlightGroups = asyncComponent(() =>
    import("./Components/FlightMaterial/FlightGroups/FlightGroups.js")
);

//---- FlightSearch ----//
const FlightSearch = asyncComponent(() =>
    import("./Components/FlightMaterial/FlightSearch/FlightSearch.js")
);

//---- SelectedFlight ----//
const SelectedFlight = asyncComponent(() =>
    import("./Components/FlightMaterial/FlightSelected/FlightSelected.js")
);

//---- Accommodation ----//
const AccommodationList = asyncComponent(() =>
    import("./Components/Accommodation/AccommodationList")
);
const MaterialAccommodationCard = asyncComponent(() =>
    import("./Components/Accommodation/MaterialAccommodationCard.jsx")
);

//---- Poi ----//
const PoiList = asyncComponent(() =>
    import("./Components/PoiMaterial/PoiList.js")
);
const PoiCard = asyncComponent(() =>
    import("./Components/PoiMaterial/PoiCard.js")
);

//---- Cars ----//
const CarsSearch = asyncComponent(() =>
    import("./Components/Cars/CarsSearch/CarsSearch.jsx")
);
//---- MaterialCars ----//
const MaterialCarsSearch = asyncComponent(() =>
    import("./Components/CarsMaterial/MaterialCarsSearch.js")
);
const CarsSearchOneStep = asyncComponent(() =>
    import("./Components/Cars/CarsSearch/CarsSearchOneStep.jsx")
);
const CarsStep = asyncComponent(() =>
    import("./Components/Cars/CarsSearch/CarsStep.jsx")
);
const CarsResults = asyncComponent(() =>
    import("./Components/Cars/CarsSearch/CarsResults.jsx")
);
const CarsAddToCart = asyncComponent(() =>
    import("./Components/Cars/CarsSearch/CarsAddToCart.jsx")
);

//---- Cart ----//
const Cart = asyncComponent(() =>
    import("./Components/CartMaterial/Cart.js")
);
const CartQuotation = asyncComponent(() => {
    import("./Components/CartMaterial/CartQuotation.js");
});
//---- Booking ----//
const Booking = asyncComponent(() =>
    import("./Components/BookingMaterial/Booking.js")
);
//---- Summary-Quotation ----//
const SummaryQuotation = asyncComponent(() =>
    import("./Components/MaterialSummaryQuotation/SummaryQuotation.js")
);
const SummaryPayment = asyncComponent(() =>
    import("./Components/MaterialSummaryQuotation/SummaryPayment.js")
);
const SummaryPaymentSuccess = asyncComponent(() =>
    import("./Components/MaterialSummaryQuotation/SummaryPaymentSuccess.js")
);
const SummaryPaymentError = asyncComponent(() =>
    import("./Components/MaterialSummaryQuotation/SummaryPaymentError.js")
);
const SummaryDocuments = asyncComponent(() =>
    import("./Components/MaterialSummaryQuotation/SummaryDocuments.js")
);
const SummaryInscription = asyncComponent(() =>
    import("./Components/MaterialSummaryQuotation/SummaryInscription.js")
);
const SummaryContact = asyncComponent(() =>
    import("./Components/MaterialSummaryQuotation/SummaryContact.js")
);

//---- Provider-Website ----//
const ProviderWebsite = asyncComponent(() =>
    import("./Components/ProviderWebsite/MaterialProviderWebsite.js")
);
const ProviderQuotation = asyncComponent(() =>
    import("./Components/ProviderQuotation/ProviderQuotation.tsx")
);
const ProviderBooking = asyncComponent(() =>
    import("./Components/ProviderBooking/ProviderBooking.tsx")
);
//---- PCancel-Website ----//
const ProviderCancel = asyncComponent(() =>
    import("./Components/ProviderWebsite/ProviderCancel.jsx")
);

//---- Home ----//
const Home = asyncComponent(() =>
    import("./Components/pages/home.tsx")
);
//---- Home visiteurs ----//
const VisiteursHome = asyncComponent(() =>
    import("./Components/VisiteursHome/VisiteursHome.js")
);
//---- ItineraryType & others products ----//
const DestinationProducts = asyncComponent(() =>
    import("./Components/pages/destination.tsx")
);
const NoDestinationProducts = asyncComponent(() =>
    import("./Components/pages/noDestination")
);
//---- ItineraryTypeDetails & others products ----//
const ItineraryTypeDetails = asyncComponent(() =>
    import("./Components/ItineraryType/ItiTypes/ItiTypeDetails.js")
);
const PackagesDetails = asyncComponent(() =>
    import("./Components/ItineraryType/PackagesMaterial/PackagesDetails.js")
);
const CircuitsDetails = asyncComponent(() =>
    import("./Components/pages/circuit.tsx")
);

//---- Menu ----//
//---- BaseMenu ----//
const BaseMenu = asyncComponent(() =>
    //import("./Components/Menu/BaseMenu/BaseMenu.jsx")
    import("./Components/Menu/BaseMenu/MaterialBaseMenu.js")
);
//---- Profile ----//
const Profile = asyncComponent(() =>
    import("./Components/Menu/Profile/MaterialProfile.js")
    //import("./Components/Menu/Profile/Profile.jsx")
);

//---- Agency Profile ----//
const AgencyProfile = asyncComponent(() =>
    import("./Components/Menu/AgencyProfile/MaterialAgencyProfile.js")
);

const SalesPersons = asyncComponent(() =>
    import("./Components/Menu/SalesPersons/salesPersons")
);

const ThemeSettings = asyncComponent(() =>
    import("./Components/Menu/Settings/theme/themeSettings.tsx")
);

const ThemeSettingsColors = asyncComponent(() =>
    import("./Components/Menu/Settings/theme/themeSettingsColors.tsx")
);

const ThemeSettingsInputs = asyncComponent(() =>
    import("./Components/Menu/Settings/theme/themeSettingsInputs.tsx")
);

const ThemeSettingsTypography = asyncComponent(() =>
    import("./Components/Menu/Settings/theme/themeSettingsTypography.tsx")
);

const ThemeSettingsButtons = asyncComponent(() =>
    import("./Components/Menu/Settings/theme/themeSettingsButtons.tsx")
);

const ThemeSettingsIcons = asyncComponent(() =>
    import("./Components/Menu/Settings/theme/themeSettingsIcons.tsx")
);

const TripList = asyncComponent(() =>
    import("./Components/Menu/MaterialTripList/TripList")
);

const TripListInfo = asyncComponent(() =>
    import("./Components/Menu/MaterialTripList/TripListInfo")
);

const CrmUserList = asyncComponent(() =>
    import("./Components/Menu/Crm/CrmUserList/CrmUserList")
);

const CrmDemandList = asyncComponent(() =>
    import("./Components/Menu/Crm/CrmDemandList/CrmDemandList")
);

const CrmDemandDetail = asyncComponent(() =>
    import("./Components/Menu/Crm/CrmDemandDetail/CrmDemandDetail")
);

const CrmUserDetail = asyncComponent(() =>
    import("./Components/Menu/Crm/CrmUserDetail/CrmUserDetail")
);

//---- AgencyMarging ----//
const AgencyMargin = asyncComponent(() =>
    //import("./Components/Menu/AgencyMargin/AgencyMargin.jsx")
    import("./Components/Menu/AgencyMargin/MarginList.js")
);

//---- AgencyUserManagement ----//
const AgencyUserManagement = asyncComponent(() =>
    import("./Components/Menu/AgencyUserManagement/AgencyUserTable.js")
);

//---- AgencyCustomerManagement ----//
const AgencyCustomerManagement = asyncComponent(() =>
    import("./Components/Menu/AgencyCustomerManagement/AgencyCustomerManagement.jsx")
);

//---- Help ----//
const Help = asyncComponent(() =>
    import("./Components/Menu/Help/TripListVideo.jsx")
);

//---- Settings ----//
const Settings = asyncComponent(() =>
    import("./Components/Menu/Settings/Settings.jsx")
);

//---- Transfers ----//
const Transfers = asyncComponent(() =>
    import("./Components/Transfers/Transfers.tsx")
);
const StepTransfers = asyncComponent(() =>
    import("./Components/Transfers/StepTransfers.jsx")
);
const CityTransfers = asyncComponent(() =>
    import("./Components/Transfers/CityTransfers.jsx")
);

const AgencyOfflineMargin = asyncComponent(() =>
    import("./Components/AgencyOfflineMargin/AgencyOfflineMargin")
);

const CmsSettings = asyncComponent(() =>
    import("./Components/Menu/Settings/CmsSettings.tsx")
);

const ThemeSettingsList = asyncComponent(() =>
    import("./Components/Menu/Settings/theme/themeSettingsList.tsx")
);

const CircuitSteps = asyncComponent(() =>
    import("./Components/pages/circuitSteps.tsx")
);

const CircuitStepsParameters = asyncComponent(() =>
    import("./Components/pages/circuitStepsTripParameters.tsx")
);

const CircuitStepsAccommodation = asyncComponent(() =>
    import("./Components/pages/circuitStepsAccommodation.tsx")
);

const CircuitStepsProducts = asyncComponent(() =>
    import("./Components/pages/circuitStepsProducts.tsx")
);

const CircuitStepsOptionalProducts = asyncComponent(() =>
    import("./Components/pages/circuitStepsOptionalProducts.tsx")
);

const CircuitStepsInsurances = asyncComponent(() =>
    import("./Components/pages/circuitStepsInsurances.tsx")
);

const CircuitStepsBookingTravelers = asyncComponent(() =>
    import("./Components/pages/circuitStepsBookingTravelers.tsx")
);

const CircuitStepsBookingCartRecap = asyncComponent(() =>
    import("./Components/pages/circuitStepsBookingCartRecap.tsx")
);

const CircuitCartSteps = asyncComponent(() =>
    import("./Components/pages/circuitCartSteps.tsx")
);

const CircuitCartStepsBookingCartRecap = asyncComponent(() =>
    import("./Components/pages/circuitCartStepsBookingCartRecap.tsx")
);

const CircuitCartStepsTravelers = asyncComponent(() =>
    import("./Components/pages/circuitCartStepsTravelers.tsx")
);

const CircuitCartStepsBooking = asyncComponent(() =>
    import("./Components/pages/circuitCartStepsBooking.tsx")
);

const CircuitCartStepsAccommodation = asyncComponent(() =>
    import("./Components/pages/circuitCartStepsAccommodation.tsx")
);

const CircuitCartStepsProducts = asyncComponent(() =>
    import("./Components/pages/circuitCartStepsProducts.tsx")
);

const CircuitCartStepsOptionalProducts = asyncComponent(() =>
    import("./Components/pages/circuitCartStepsOptionalProducts.tsx")
);

const CircuitCartStepsFlights = asyncComponent(() =>
    import("./Components/pages/circuitCartStepsFlights.tsx")
);

const CircuitCartStepsInsurances = asyncComponent(() =>
    import("./Components/pages/circuitCartStepsInsurances.tsx")
);

const Destinations = asyncComponent(() =>
    import("./Components/Home/MaterialHome")
);

const Booklets = asyncComponent(() =>
    import("./Components/pages/booklets")
);

const OurContacts = asyncComponent(() =>
    import("./Components/pages/ourContacts")
);

const Insurances = asyncComponent(() =>
    import("./Components/CartMaterial/insurances")
);

const Travelers = asyncComponent(() =>
    import("./Components/CartMaterial/travelers")
);

const requireAuth = (router, replace, callback) => {
    let token = GetCookie("token");
    if ((token === undefined || token === null || token === "") && !(['visiteurs'].includes(identifier))) {
        replace({
            pathname: `/home`,
            state: { nextPathname: router.location.pathname }
        });
        callback();
    }
    callback();
};

const checkDepartureReturn = (router, replace, callback, store) => {
    if (store.getState().itinerary.departure_destination.city === null && store.getState().itinerary.return_destination.city === null) {
        replace({
            pathname: `/${router.params.url_name}/apps/itinerary`,
            state: { nextPathname: router.location.pathname }
        });
    }
    callback();
};

const checkFlight = (router, replace, callback, store) => {
    if (store.getState().flight.index_group === null || store.getState().flight.copy_index_group === null) {
        replace({
            pathname: `/${router.params.url_name}/apps/flight-groups`,
            state: { nextPathname: router.location.pathname }
        });
    }
    callback();
};

const getInstance = (url_name, store) => {
    // let domain_name = window.location.host.replace("www.", "");
    // let customer_identifier = "";
    // if (url_name !== undefined && url_name !== null) {
    //     customer_identifier = url_name;
    // } else {
    //     customer_identifier = domain_name.split('.')[0];
    // }
    // if (window.location.host === "localhost:3000") {
    //     domain_name = "dev.facilitatrip.com";
    //     if (url_name === undefined || url_name === null) {
    //         customer_identifier = "dev";
    //     }
    // }
    let domain_name = "beachcomber.facilitatrip.fr";
    let customer_identifier = "beachcomber";
    $.ajax({
        method: "GET",
        async: false,
        url: `${API_HREF}instances/?customer_identifier=${customer_identifier}&domain_name=${domain_name}`,
        success: function (data) {
            console.log(data);
            if (data.favicon !== undefined && data.favicon !== null && data.favicon.thumbnail_little !== undefined && data.favicon.url !== undefined) {
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = (data.favicon.thumbnail_little !== null ? data.favicon.thumbnail_little : data.favicon.url);
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            if (data.analytics !== null) {
                ReactGA.initialize(data.analytics);
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
            localStorage.setItem("config", JSON.stringify(data));
            if (data.quotation_code === "amerigo" && !store.getState().accommodation.recommended) {
                store.dispatch(ToggleAccommodationRecommended());
            }
            window.id_owner = data.instance_id;
            window.id_buyer = data.client_id;
            window.url_name = customer_identifier;
        },
        error: function (error) {
            console.log(error);
            if (error !== undefined && error !== null && error.status !== undefined && error.status !== null && error.status === 404) {
                Notify(/*i18n.t("notify.wrong_instance")*/ "L'instance que vous essayez d'accéder n'existe pas, veuillez vérifier votre url d'accès", { status: "danger", pos: "top-center" });
            }
        }
    });
};

const getLocales = (store) => {
    const { pass_check, headers } = CheckBeforeRequest();
    $.ajax({
        method: "GET",
        url: `${API_HREF}locales/`,
        headers: headers,
        success: function (data) {
            const default_locale = data.results.find((el) => {
                return el.language_code === 'fr';
            });
            const main_locale = data.results.find((el) => {
                return el.language_code === i18n.language;
            });
            store.dispatch(SetLocales(data.results));
            store.dispatch({ type: "USER_SET_MAIN_LOCALE", payload: main_locale ? main_locale : default_locale });
        },
        error: function (error) {
            console.log(error);
        }
    });
};

const getCurrencies = (store) => {
    const { pass_check, headers } = CheckBeforeRequest();
    if (store.getState().base.currency_list.length === 0) {
        $.ajax({
            method: "GET",
            url: `${API_HREF}currencies/?limit=200`,
            headers: headers,
            success: function (data) {
                if (data.results.length > 0) {
                    store.dispatch(SetCurrencyList(data.results));
                }
            },
            error: function (error) {
                console.log(error);
            }
        });
    }
};

const loadProfile = (store, router) => {
    let token = GetCookie("token");
    let id_user = GetCookie("id_user");
    let client_user = GetCookie("client_user");
    let domain_name = window.location.host.replace("www.", "");
    if (store.getState().user.user === null && token !== undefined && token !== null && token !== "") {
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${client_user}/user/${id_user}/`,
            async: false,
            headers: {
                "Authorization": "Token " + token,
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            success: function (data) {
                console.log(data);
                if (JSON.parse(localStorage.getItem("config")).quotation_code === "amerigo" && !data.is_superuser) {
                    Notify("FacilitaTrip est forcée d'interrompre le service Free Independent Traveler pour le compte du tour-opérateur Amerigo. Nous en sommes sincèrement désolés, mais nous poursuivons l'aventure avec d'autres partenaires", { status: "danger", pos: "top-center" });
                } else if (JSON.parse(localStorage.getItem("config")).quotation_code === "pbe" && !data.is_superuser) {
                    Notify("FacilitaTrip est forcée d'interrompre le service Free Independent Traveler pour le compte du tour-opérateur Plus Belle l'Europe. Nous en sommes sincèrement désolés, mais nous poursuivons l'aventure avec d'autres partenaires", { status: "danger", pos: "top-center" });
                } else {
                    store.dispatch(SetUserAndConnection(data, true));
                    if (!!data.lang && data.lang.split("-").length > 0) {
                        store.dispatch({ type: 'CART_SET_QUOTATION_TARGET_LANGUAGE', payload: data.lang.split("-")[0] })
                    }
                    if (data.is_superuser === false) {
                        $.ajax({
                            method: "GET",
                            url: `${API_HREF}client/${client_user}/user/${id_user}/permissions/?all=true`,
                            async: false,
                            headers: {
                                "Authorization": "Token " + token,
                                "Accept": "application/json",
                                "Content-Type": "application/json"
                            },
                            success: function (data_perm) {
                                console.log('data_perm:', data_perm);
                                store.dispatch(SetUserPermissions(data_perm));
                            },
                            error: function (error) {
                                console.log(error);
                            }
                        });
                    } else {
                        $.ajax({
                            method: "GET",
                            url: `${API_HREF}permissions/?all=true`,
                            async: false,
                            headers: {
                                "Authorization": "Token " + token,
                                "Accept": "application/json",
                                "Content-Type": "application/json"
                            },
                            success: function (data_perm) {
                                console.log('data_perm:', data_perm);
                                store.dispatch(SetUserPermissions(data_perm));
                            },
                            error: function (error) {
                                console.log(error);
                            }
                        });
                    }
                    if (client_user !== undefined && client_user !== null && client_user !== "" && router.location.pathname === "/home") {
                        window.location = `/home/dev`;
                        // if (window.location.host === "localhost:3000") {
                        //     window.location = `/home/dev`;
                        // } else if (data.client_full.type !== 2) {
                        //     window.location = `/home/${domain_name.split('.')[0]}`;
                        // } else {
                        //     window.location = `/home/${client_user}`;
                        // }
                    }
                }
            },
            error: function (error) {
                console.log(error);
            }
        });
    } else if (client_user !== undefined && client_user !== null && client_user !== "" && router.location.pathname === "/home") {
        window.location = `/home/dev`;
        // if (window.location.host === "localhost:3000") {
        //     window.location = `/home/dev`;
        // } else if (store.getState().user.user.client_full.type !== 2) {
        //     window.location = `/home/${domain_name.split('.')[0]}`;
        // } else {
        //     window.location = `/home/${client_user}`;
        // }
    }
};

const loadTrip = (router) => {
    let user_token = GetCookie("token");
    let id_user = GetCookie("id_user");
    let client_user = GetCookie("client_user");
    //Delete the cookie
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
        let d = window.location.hostname.split(".");
        while (d.length > 0) {
            let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
            let p = location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
            }
            d.shift();
        }
    }
    SetCookie("trip_id", router.params.trip_id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("trip_id_version", router.params.trip_version, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("trip_token", router.params.trip_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    window.location = `/${router.params.url_name}/apps/itinerary`;
};

// if (
//     window.location.hostname.split('.')[2] === 'com' &&
//     window.location.hostname !== 'demo.facilitatrip.com' &&
//     !window.location.host.includes('localhost')
// ) {
//     Sentry.init({
//         dsn: "https://6416f007833e40b8ad27b4947cb330e0@o161232.ingest.sentry.io/1227144",
//         integrations: [new BrowserTracing()],
//
//         //We recommend adjusting this value in production, or using tracesSampler
//         //for finer control
//         tracesSampleRate: 1.0
//     });
// }

async function enableMocking() {
    const { worker } = await import('./mocks/browser.ts');

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start()
}

enableMocking().then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <Suspense fallback="loading">
                    <StylesProvider generateClassName={generateClassName}>
                        <ThemeProvider>
                            <CssBaseline>
                                <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }} disableWindowBlurListener={true} action={key => CloseSnackbar(key)}>
                                    <Router history={browserHistory}>
                                        <Route path={"access/:url_name/:trip_id/:trip_version/:trip_token"} onEnter={(router) => {
                                            loadTrip(router);
                                        }} />
                                        <Route path={"agency-price-edit/:trip_id/:trip_version/:trip_token/:token"} component={AgencyOfflineMargin} onEnter={(router) => {
                                            getInstance(router.params.url_name, store);
                                            getCurrencies(store);
                                            getLocales(store);
                                        }} />
                                        <Route path={"home(/:url_name)"} component={identifier !== 'visiteurs' ? Home : VisiteursHome} onEnter={(router) => {
                                            loadProfile(store, router);
                                            getInstance(router.params.url_name, store);
                                            getCurrencies(store);
                                            getLocales(store);
                                        }} />
                                        <Route path={"/:url_name"} onEnter={(router) => {
                                            loadProfile(store, router);
                                            getInstance(router.params.url_name, store);
                                            getCurrencies(store);
                                            getLocales(store);
                                        }}>
                                            <Route path="destinations" component={Destinations} />
                                            <Route path={"reset-password"} component={identifier !== 'visiteurs' ? Home : VisiteursHome} />
                                            <Route path={"destination-product/:desti_id"} component={DestinationProducts} onEnter={(router, replace, callback) => {
                                                requireAuth(router, replace, callback);
                                            }} />
                                            <Route path={"no-destination"} component={NoDestinationProducts} onEnter={(router, replace, callback) => {
                                                requireAuth(router, replace, callback);
                                            }} />
                                            <Route path={"booklets"} component={Booklets} />
                                            <Route path={"our-contacts"} component={OurContacts} onEnter={(router, replace, callback) => {
                                                requireAuth(router, replace, callback);
                                            }} />
                                            <Route path={"packages-details/:itinerary_type_name/:itinerary_type_id"} component={PackagesDetails} onEnter={(router, replace, callback) => {
                                                requireAuth(router, replace, callback);
                                            }} />
                                            <Route path={"circuits-details/:itinerary_type_name/:itinerary_type_id(/:version)"} component={CircuitsDetails} onEnter={(router, replace, callback) => {
                                                requireAuth(router, replace, callback);
                                            }} />
                                            <Route path={"itinerary-type-details/:itinerary_type_name/:itinerary_type_id"} component={ItineraryTypeDetails} onEnter={(router, replace, callback) => {
                                                requireAuth(router, replace, callback);
                                            }} />
                                            <Route path={"summary-quotation/payment/:trip_id/:trip_id_version/:trip_token"} component={SummaryPayment} show_map_calendar={false} />
                                            <Route path={"summary-quotation/:trip_id/:trip_id_version/:trip_token"} component={SummaryQuotation} show_map_calendar={false} />
                                            <Route path={"summary-inscription/:trip_id/:trip_id_version/:trip_token"} component={SummaryInscription} show_map_calendar={false} />
                                            <Route path={"summary-documents/:trip_id/:trip_id_version/:trip_token"} component={SummaryDocuments} show_map_calendar={false} />
                                            <Route path={"summary-contact/:trip_id/:trip_id_version/:trip_token"} component={SummaryContact} show_map_calendar={false} />
                                            <Route path={"summary-quotation/payment/success/:trip_id/:trip_id_version/:trip_token"} component={SummaryPaymentSuccess} show_map_calendar={false} />
                                            <Route path={"summary-quotation/payment/error/:trip_id/:trip_id_version/:trip_token"} component={SummaryPaymentError} show_map_calendar={false} />
                                            <Route path={"provider-website/:trip_id/:trip_id_version/:trip_token/:product_type/:product_id"} component={ProviderWebsite} show_map_calendar={false} />
                                            <Route path={"provider-quotation/provider/:providerId/trip/:tripId/version/:tripVersion/stack/:stackNumber/token/:tripToken"} component={ProviderQuotation} show_map_calendar={false} />
                                            <Route path={"provider-booking/provider/:providerId/trip/:tripId/version/:tripVersion/token/:tripToken"} component={ProviderBooking} show_map_calendar={false} />
                                            <Route path={"provider-cancel/:trip_id/:trip_id_version/:trip_token/:product_type/:product_id"} component={ProviderCancel} show_map_calendar={false} />
                                            <Route path={"apps"} component={Base} onEnter={(router, replace, callback) => {
                                                requireAuth(router, replace, callback);
                                            }}>
                                                <IndexRedirect to={"itinerary"} />
                                                {
                                                    //<Route path={ "steps" } component={ AppsSteps } show_map_calendar={ true } type={ 0 }/>
                                                }
                                                <Route path={"itinerary"} component={Itinerary} show_map_calendar={true} type={0} timeline={false} />
                                                <Route path={"packages"} component={Itinerary} show_map_calendar={true} type={0} timeline={false} />
                                                <Route path={"cruises"} component={Itinerary} show_map_calendar={true} type={0} timeline={false} />
                                                <Route path={"flight-groups"} component={FlightGroups} show_map_calendar={false} timeline={false} />
                                                <Route
                                                    path={"flight-search/:use_cache"}
                                                    component={(props) => (
                                                        <FlightSearch
                                                            {...props}
                                                            isCms
                                                        />
                                                    )}
                                                    show_map_calendar={false}
                                                    onEnter={(router, replace, callback) => {
                                                        checkFlight(router, replace, callback, store);
                                                    }}
                                                    timeline={false}
                                                />
                                                <Route path={"selected-flight"} component={SelectedFlight} show_map_calendar={false} onEnter={(router, replace, callback) => {
                                                    checkFlight(router, replace, callback, store);
                                                }} timeline={false} />
                                                <Route path={"accommodation"} component={AccommodationList} show_map_calendar={true} type={1} timeline={true} />
                                                <Route path={"accommodation-card/:id"} component={MaterialAccommodationCard} type={1} show_map_calendar={false} timeline={false} />
                                                <Route path={"poi"} component={PoiList} show_map_calendar={true} type={2} timeline={true} />
                                                <Route path={"poi-card/:id"} component={PoiCard} show_map_calendar={false} timeline={false} />
                                                <Route path={"cart"} component={Cart} show_map_calendar={false} timeline={false} />
                                                <Route path={"insurances"} component={Insurances} show_map_calendar={false} timeline={false} />
                                                <Route path={"travelers"} component={Travelers} show_map_calendar={false} timeline={false} />
                                                <Route path={"cart-quotation"} component={CartQuotation} show_map_calendar={false} timeline={false} />
                                                <Route path={"booking"} component={Booking} show_map_calendar={false} timeline={false} />
                                                <Route path={"cars-search"} component={CarsSearch} show_map_calendar={false} onEnter={(router, replace, callback) => {
                                                    checkDepartureReturn(router, replace, callback, store);
                                                }} timeline={false} />
                                                <Route path={"material-cars-search"} component={MaterialCarsSearch} timeline={false} show_map_calendar={false} name={""} />
                                                <Route path={"cars-search-one-step"} component={CarsSearchOneStep} show_map_calendar={false} onEnter={(router, replace, callback) => {
                                                    checkDepartureReturn(router, replace, callback, store);
                                                }} timeline={false} />
                                                <Route path={"cars-step"} component={CarsStep} show_map_calendar={false} onEnter={(router, replace, callback) => {
                                                    checkDepartureReturn(router, replace, callback, store);
                                                }} timeline={false} />
                                                <Route path={"cars-results/:id_step"} component={CarsResults} show_map_calendar={false} onEnter={(router, replace, callback) => {
                                                    checkDepartureReturn(router, replace, callback, store);
                                                }} timeline={false} />
                                                <Route path={"cars-add-to-cart/:id_step"} component={CarsAddToCart} show_map_calendar={false} onEnter={(router, replace, callback) => {
                                                    checkDepartureReturn(router, replace, callback, store);
                                                }} timeline={false} />
                                                <Route path={"transfers"} component={Transfers} show_map_calendar={false} timeline={false} />
                                                <Route path={"step-transfers"} component={StepTransfers} show_map_calendar={false} timeline={false} />
                                                <Route path={"city-transfers"} component={CityTransfers} show_map_calendar={false} timeline={false} />
                                                <Route
                                                    path="circuit-steps"
                                                    component={CircuitSteps}
                                                    show_map_calendar={false}
                                                    onEnter={(_, replace) => {
                                                        const circuit = store.getState().page.circuit.package.data;
                                                        const url = localStorage.getItem('circuit-steps-last-circuit');
                                                        if (!circuit && url) {
                                                            const parsedUrl = new URL(url);
                                                            replace({
                                                                pathname: parsedUrl.pathname,
                                                            });
                                                        }
                                                    }}
                                                    onLeave={() => {
                                                        store.dispatch(resetCircuit());
                                                    }}
                                                    timeline={false}
                                                    disabledLoader
                                                    disabledHeader
                                                >
                                                    <Route
                                                        path="parameters"
                                                        component={CircuitStepsParameters}
                                                    />
                                                    <Route
                                                        path={"flight-search/:use_cache"}
                                                        component={(props) => (
                                                            <FlightSearch
                                                                {...props}
                                                                contentOnly
                                                                isCircuitPrecart
                                                            />
                                                        )}
                                                        show_map_calendar={false}
                                                        timeline={false}
                                                    />
                                                    <Route
                                                        path={"selected-flight"}
                                                        component={(props) => (
                                                            <SelectedFlight
                                                                {...props}
                                                                isCircuitPrecart
                                                            />
                                                        )}
                                                        show_map_calendar={false}
                                                        onEnter={(router, replace, callback) => {
                                                            checkFlight(router, replace, callback, store);
                                                        }}
                                                        timeline={false}
                                                    />
                                                    <Route
                                                        path="accommodation"
                                                        component={CircuitStepsAccommodation}
                                                    />
                                                    <Route path="options">
                                                        <IndexRedirect to={"included"} />
                                                        <Route
                                                            path="included"
                                                            component={CircuitStepsProducts}
                                                        />
                                                        <Route
                                                            path="optional"
                                                            component={CircuitStepsOptionalProducts}
                                                        />
                                                    </Route>
                                                    <Route
                                                        path="insurance"
                                                        component={CircuitStepsInsurances}
                                                    />
                                                    <Route path="booking">
                                                        <IndexRedirect to={"travelers"} />
                                                        <Route path="travelers" component={CircuitStepsBookingTravelers} />
                                                        <Route path="cart-recap" component={CircuitStepsBookingCartRecap} />
                                                    </Route>
                                                </Route>
                                                <Route
                                                    path="circuit-cart-steps"
                                                    component={CircuitCartSteps}
                                                    show_map_calendar={false}
                                                    timeline={false}
                                                    disabledLoader
                                                    disabledHeader
                                                >
                                                    <Route
                                                        path="booking"
                                                        component={CircuitCartStepsBooking}
                                                    >
                                                        <IndexRedirect to={"cart-recap"} />
                                                        <Route path="travelers" component={CircuitCartStepsTravelers} />
                                                        <Route path="cart-recap" component={CircuitCartStepsBookingCartRecap} />
                                                    </Route>
                                                    <Route path="accommodation" component={CircuitCartStepsAccommodation} />
                                                    <Route path="insurance" component={CircuitCartStepsInsurances} />
                                                    <Route path="flight" component={CircuitCartStepsFlights} />
                                                    <Route path="options">
                                                        <IndexRedirect to={"included"} />
                                                        <Route path="included" component={CircuitCartStepsProducts} />
                                                        <Route path="optional" component={CircuitCartStepsOptionalProducts} />
                                                    </Route>
                                                </Route>
                                            </Route>
                                            <Route path={"content/settings"} component={CmsSettings}>
                                                <IndexRedirect to={"themes"} />
                                                <Route path="themes" component={ThemeSettingsList} />
                                                <Route path="themes/colors/:themeId" component={ThemeSettingsColors} />
                                                <Route path="themes/inputs/:themeId" component={ThemeSettingsInputs} />
                                                <Route path="themes/typography/:themeId" component={ThemeSettingsTypography} />
                                                <Route path="themes/buttons/:themeId" component={ThemeSettingsButtons} />
                                                <Route path="themes/icons/:themeId" component={ThemeSettingsIcons} />
                                            </Route>
                                            <Route path={"menu"} component={BaseMenu} onEnter={(router, replace, callback) => {
                                                requireAuth(router, replace, callback);
                                            }} timeline={false}>
                                                <IndexRedirect to={"profile"} />
                                                <Route path={"profile"} component={Profile} />
                                                <Route path={"agency-profile"} component={AgencyProfile} />
                                                <Route path={"salespersons"} component={SalesPersons} />
                                                <Route path={"trip-list"} component={TripList} disabledHeader timeline={false} />
                                                <Route path={"trip-info/:trip_id"} component={TripListInfo} />
                                                <Route path={"crm"} component={CrmUserList} />
                                                <Route path={"crm-user/:user_id"} component={CrmUserDetail} />
                                                <Route path={"crm-demands"} component={CrmDemandList} />
                                                <Route path={"crm-demand/:demand_id"} component={CrmDemandDetail} />
                                                <Route path={"agency-margin"} component={AgencyMargin} />
                                                <Route path={"agency-user-management"} component={AgencyUserManagement} />
                                                <Route path={"agency-customer-management"} component={AgencyCustomerManagement} />
                                                <Route path={"help"} component={Help} />
                                                <Route path={"settings"} component={Settings} />
                                            </Route>
                                        </Route>
                                        {
                                            /*
                                    <Route path={ "/not-found" } component={ NotFound }/>
                                    <Route path={ "*" } component={ NotFound }/>
                                         */
                                        }
                                    </Router>
                                </SnackbarProvider>
                            </CssBaseline>
                        </ThemeProvider>
                    </StylesProvider>
                </Suspense>
            </I18nextProvider>
        </Provider>,
        document.getElementById("app")
    )
});
